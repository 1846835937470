#section-about {
  background: #005b94;
  /* padding: 90px 0 150px 0 !important; */
}

.img-fluid {
  border-radius: 15px !important;
  max-width: 100%;
  height: auto;
}

.textBlack {
  color: black !important;
}

.eventokikuchi {
  margin-top: 30px;
  width: 100%;
  border-radius: 15px !important;
}

.di-small-2 {
  position: absolute;
  width: 40%;
  height: 40%;
  right: 5%;
  bottom: -30%;
  border-radius: 15px !important;
}

@media only screen and (max-width: 767px) {
  #section-about {
    /* padding: 90px 0 90px 0 !important; */
  }

  .eventokikuchi {
    position: absolute;
    width: 40%;
    height: 40%;
    right: 20% !important;
    bottom: -60%;
    left: 67%;
    border-radius: 10px !important;
  }

  .di-small-2 {
    position: absolute;
    width: 40%;
    height: 40%;
    right: 2% !important;
    right: 20px;
    bottom: -30%;
    border-radius: 10px !important;
  }
}

/* @media only screen and (min-width: 768px) {
  .eventokikuchi {
    position: absolute;
    width: 40%;
    height: 40%;
    right: 20% !important;
    bottom: -60%;
    left: 30%;
    border-radius: 10px !important;
  }
} */

@media only screen and (max-width: 993px) {
  .eventokikuchi {
    position: absolute;
    width: 40%;
    height: 40%;
    right: 20% !important;
    bottom: -60%;
    left: 30%;
    border-radius: 10px !important;
  }
}
