.text-terms h1 {
  padding: 40px 0 0 0;
}

.text-terms h2 {
  padding: 40px 0 0 0;
  color: black;
  font-size: 20px;
}

.text-terms p {
  text-indent: 1.5em;
  text-align: justify;
  color: black !important;
}

.text-terms ul {
  color: black;
}
