.loading {
  width: auto;
  height: 100vh;
  background-color: #fff;
  display:flex;
  justify-content: center;
  align-items: center;
}

.loading img {
  width: 40%;
  max-width: 980px;
}