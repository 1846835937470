.bg-color,
section.bg-color,
section.call-to-action,
#mainmenu li li a:hover,
.price-row,
.blog-list .date,
.blog-read .date,
.slider-info .text1,
#filters a.selected,
.btn-primary,
.bg-id-color,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.dropcap,
.fullwidthbanner-container a.btn,
.feature-box-big-icon i,
#testimonial-full,
.icon-deco i,
.blog-list .date-box .day,
.blog-read .date-box .day,
.bloglist .date-box .day,
.feature-box-small-icon .border,
.small-border,
#jpreBar,
.date-post,
.team-list .small-border,
.de-team-list .small-border,
.btn-line:hover,
a.btn-line:hover,
.btn-line.hover,
a.btn-line.hover,
.owl-arrow span,
.de-progress .progress-bar,
#btn-close-x:hover,
.box-fx .info,
.de_tab.tab_steps .de_nav li span,
.de_testi blockquote:before,
#services-list li.active,
#services-list li a:hover,
.btn-more,
.widget .small-border,
.product img:hover,
#btn-search,
.de_tab.timeline li.active .dot,
.custom-show:after,
.custom-close:after,
#back-to-top,
a.btn-custom,
.owl-custom-nav .btn-next:before,
.owl-custom-nav .btn-prev:before,
#mainmenu ul li:hover > a,
.pricing-s1 .ribbon,
a.btn-slider.btn-color,
.owl-theme .owl-controls .owl-buttons div,
.owl-theme .owl-dots .owl-dot.active span,
.social-icons i:hover,
#menu-btn:hover,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.picframe.s2 span.overlay,
input[type='submit'].btn-custom,
#contact_form input[type='submit'].btn-line:hover,
.tiny-border,
.de_tab.tab_style_4 .de_nav li.active,
.de_tab.tab_style_4 .de_nav li.active span,
.de_tab.tab_style_4 .de_nav.de_nav_dark li:hover,
.countdown-s1.countdown-bg-color .countdown-section,
.schedule-item:hover .sc-pic img {
  background-color: var(--primary-color-1);
}

.bg-color-2,
.pricing-s1 .ribbon,
.box-number:hover i.bg-color.hover-color-2 {
  background-color: var(--secondary-color);
}

section.gradient-to-right,
.ps1-deco {
  background-color: var(--terciary-color);
  /* background: linear-gradient(
    90deg,
    rgb(7, 138, 164) 0%,
    rgb(113, 198, 172) 90%
  ); */
}

section.gradient_to_right {
  background: rgb(1, 116, 171);
  background: linear-gradient(
    90deg,
    rgba(1, 116, 171, 1) 0%,
    rgba(49, 161, 214, 1) 100%
  );
}

.feature-box i,
#mainmenu li:hover > ul,
.date-box .day,
.slider_text h1,
h1.id-color,
h2.id-color,
h3.id-color,
h4.id-color,
h5.id-color,
h6.id-color,
.pricing-box li h1,
.title span,
i.large:hover,
.feature-box-small-icon-2 i,
address span i,
.pricing-dark .pricing-box li.price-row,
.price,
#mainmenu a:hover,
#mainmenu a.active,
header.smaller #mainmenu a.active,
.pricing-dark .pricing-box li.price-row,
.dark .feature-box-small-icon i,
a.btn-slider:after,
.feature-box-small-icon i,
a.btn-line:after,
.team-list .social a,
.de_contact_info i,
.de_count,
.dark .btn-line:hover:after,
.dark a.btn-line:hover:after,
.dark a.btn-line.hover:after,
a.btn-text:after,
address span strong,
.de_tab.tab_steps .de_nav li span:hover,
.de_testi_by,
.pf_text,
.widget_tags li a,
.dark .btn-line:after,
.dark a.btn-line:after,
.crumb a,
.btn-right:after,
.btn-left:before,
.de_form input[type='radio']:checked + label:before,
.de_form input[type='checkbox']:checked + label:before,
#mainmenu li:hover > a,
.expand h4:after,
.pricing-s1 .bottom ul li i,
footer a:hover,
.picframe .project-name,
blockquote.testimonial-big:before,
.btn.btn-link,
.de_light a.btn-line:hover,
.schedule-item .sc-name span,
.de_tab.tab_style_4 .de_nav li span,
.list-inline-style-1 li:before {
  color: var(--primary-color-1);
}

.id-color {
  color: var(--terciary-color);
}

#mainmenu li a:after,
.separator span i {
  color: var(--terciary-color) !important;
}

#mainmenu li a:after,
.separator2 {
  color: var(--terciary-color);
  font-size: 15px;
}

.id-color-2,
h1.id-color-2,
h2.id-color-2,
h3.id-color-2,
h4.id-color-2,
h5.id-color-2 {
  color: var(--secondary-color);
}

.feature-box i,
#filters a:hover,
#filters a.selected,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus .feature-box-big-icon i:after,
.btn-line:hover,
a.btn-line:hover,
.btn-line.hover,
a.btn-line.hover,
.product img:hover,
#contact_form input[type='text']:focus,
#contact_form textarea:focus,
#search:focus,
#contact_form .de_light input[type='text']:focus,
#contact_form .de_lighttextarea:focus,
#contact_form .de_light #search:focus,
.form-transparent input[type='text']:focus,
.form-transparent textarea:focus,
.form-transparent input[type='email']:focus,
a.btn-slider.btn-color,
.de_tab.tab_style_4 .de_nav {
  border-color: var(--primary-color-1);
}

.social-icons i {
  border-color: var(--primary-color-1);
  border-radius: 50% 50%;
}

.social-icons i:hover {
  transform: scale(1.2);
  transition: 0.2s;
}

.box-fx .inner,
.dark .box-fx .inner,
.blog-list img,
.arrow-up {
  border-bottom-color: var(--primary-color-1);
}

.arrow-down {
  border-top-color: var(--primary-color-1);
}

.callbacks_nav {
  background-color: var(--primary-color-1);
}

.de_tab .de_nav li span {
  border-top: 3px solid var(--primary-color-1);
}

.feature-box-big-icon i:after {
  border-color: var(--primary-color-1) transparent; /*same colour as the lower of the bubble's gradient*/
}

.de_review li.active img {
  border: solid 4px var(--primary-color-1);
}

blockquote.s1 {
  border-left-color: var(--primary-color-1);
}

.de_tab.tab_style_4 .de_nav li {
  border-right-color: var(--primary-color-1);
}
