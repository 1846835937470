.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* border: 1px solid #fff; */
}
.menu-btn__burger {
  width: 24px;
  height: 2px;
  background: #212529;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: #212529;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.menu-btn__burger::before {
  transform: translateY(-8px);
}
.menu-btn__burger::after {
  transform: translateY(8px);
}

/* animation 1 */

/* .menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
} */

/* animation 2 */

.menu-btn.open .menu-btn__burger {
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg);
}

/* animation 3 */

/* .menu-btn.open .menu-btn__burger {
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(-45deg);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(45deg);
} */
