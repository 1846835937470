@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600&display=swap');

.countdown-section {	
	display: block;
	float: left;
	
	text-align: center;
	color:rgba(255,255,255,.6);
	text-transform:uppercase;
	letter-spacing:1px;
}
.countdown-rtl {
	direction: rtl;
}
.countdown-holding span {
	color: #888;
}
.countdown-row {
	clear: both;
	width: 100%;
	padding: 0;
	text-align: center;
}
a#section-register {
    margin: 0 10px;
}
.countdown-show1 .countdown-section {
	width: 98%;
}
.countdown-show2 .countdown-section {
	width: 48%;
}
.countdown-show3 .countdown-section {
	width: 32.5%;
}
.countdown-show4 .countdown-section {
	width: 24.5%;
}
.countdown-show5 .countdown-section {
	width: 19.5%;
}
.countdown-show6 .countdown-section {
	width: 16.25%;
}
.countdown-show7 .countdown-section {
	width: 14%;
}
span.countdown-row.countdown-show4.countdownNew,
span.countdown-row.countdown-show4.countdown24,
span.countdown-row.countdown-show4.countdown00 {
    display: flex;
	justify-content: center;
	font-size: 21px;
    padding-bottom: 19px;
}
.countdown-amount {
	font-family: 'Roboto Mono', monospace, 'Montserrat',Arial, Helvetica, sans-serif;
  font-size: 72px;
	color:#fff;
}
.countdown-period {
  display: block;
	margin:0 auto;
	margin-top:5px;
}
.countdown-descr {
	display: block;
	width: 100%;
}
.countdown-s1{
	display:inline-block;
}

.countdown-s1 .countdown-show4 .countdown-section {
	width: 80px;
	float:left;
	background:rgba(255,255,255,.1);
	padding:20px 0 10px 0;
	margin-right:10px;
}

.countdown-s1.no-bg .countdown-section{
	background:none;
}

.countdown-s1 .countdown-amount {
	width:auto;
	margin:0;
	font-size:26px;
	font-weight:400;
}

.countdown-s1 .countdown-period {
	width:auto;
	margin:0 auto;
	font-weight:400;
	font-size:12px;
}

span a {
    text-decoration: revert;
}
#countdownNew {
	text-align: center;
	font-size: 22px;
}

@media only screen and (max-width: 767px){
	.countdown-amount {
		font-size: 36px;
	}
}
