#section-schedule {
  background-color: #4a90e2; /* Blue background similar to the image */
  padding: 50px 0;
}

.schedule-header {
  color: #e2ed09; /* Yellow color for title */
  font-weight: bold;
  font-size: 36px;
}

.schedule-container {
  margin: 20px 0;
  color: white;
}

.schedule-title {
  display: flex;
  align-items: flex-start;
  color: #e2ed09;
}

.schedule-section {
  margin-bottom: 30px;
}

.schedule-events {
  margin-top: 20px;
}

.schedule-event {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.event-time {
  font-weight: bold;
  color: #e2ed09; /* Yellow time slots */
}

.event-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-grow: 1;
  /* margin-left: 15px; */
}

.event-title {
  font-weight: bold;
  color: white; /* White for event titles */
}

.event-speaker {
  color: #d0d9f5; /* Lighter text color for speakers */
}
